<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col cols="2">
              <div class="mt-5">
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('messages.search_parttimer_list_text')
                }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0 "
                  outlined
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div class="align-self-center mr-9">
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('table.headers.customerName')
                }}</span>
                <v-autocomplete
                  v-model="customerSelected"
                  :items="customers"
                  :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                  item-value="id"
                  :no-data-text="$t('table.messages.no_data')"
                  clearable
                  single-line
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="3">
              <div class="align-self-center mr-9">
                <span class="forth--text text-h4 font-weight-bold ">
                  {{ $t('table.headers.job') }}
                </span>
                <v-select
                  v-model="jobSelected"
                  :items="jobLists"
                  item-text="name"
                  item-value="id"
                  outlined
                  :no-data-text="$t('table.messages.no_data')"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="align-self-center mr-9">
                <span class="forth--text text-h4 font-weight-bold ">
                  {{ $t('table.titles.dayOfWeek') }}
                </span>
                <v-select
                  v-model="requestType"
                  :items="requestTypes"
                  item-text="name"
                  item-value="value"
                  multiple
                  outlined
                  :no-data-text="$t('table.messages.no_data')"
                  @change="changeRoute"
                />
              </div>
            </v-col>
            <v-col
              cols="2"
              class="d-flex"
            >
              <div class="align-self-center mr-9">
                <v-btn
                  large
                  color="forth"
                  class="pt-0 pl-10 pr-10 white--text d-flex justify-star"
                  @click="onSearch()"
                >
                  <span class="font-weight-bold text-h5">
                    {{ $t('messages.display') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-btn
                dense
                color="forth"
                class="mb-5 pt-0 pl-10 pr-10 white--text"
                @click="openPopupCreate()"
              >
                <span class="font-weight-bold text-h5">
                  {{ $t('messages.Create') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.index`]="props">
        {{ items.indexOf(props.item) + 1 }}
      </template>
      <template v-slot:[`item.customerName`]="props">
        <a>
          {{ props.item.customer.user.name }}
        </a>
      </template>
      <template v-slot:[`item.name`]="props">
        <a @click="openPopupUpdate(props.item)">
          {{ props.item.parttimeEmployee.user.name }}
        </a>
      </template>
      <template v-slot:[`item.manageId`]="props">
        {{ props.item.parttimeEmployee.manageId }}
      </template>
      <template v-slot:[`item.furiganaName`]="props">
        {{ props.item.parttimeEmployee.furiganaName }}
      </template>
      <template v-slot:[`item.job`]="props">
        {{ props.item.job.name }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ item.shift.shiftStartAt + ' ~ ' + item.shift.shiftEndAt }}
      </template>
      <template
        v-for="i in 7"
        v-slot:[`item.${i-1}`]="{ item }"
      >
        <v-simple-checkbox
          :key="i"
          v-model="item.workdateFormat[i-1]"
          color="success"
          :value="true"
          readonly
        />
      </template>
      <template v-slot:[`footer.page-text`]="props">
        {{ totalItems }}
        {{ $t("table.headers.page-text") }}
        {{ props.pageStart }} - {{ props.pageStop }}
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="1100px"
      @click:outside="closeDialog"
    >
      <v-card>
        <validation-observer
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(updateShiftFixed)"
          >
            <v-card-title
              class="font-weight-bold text-h4 color-current-date mb-5"
            >
              <v-row>
                <v-col>
                  <div class="text-center">
                    固定シフトを作成ために以下の情報をご指定ください。
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row v-if="isEdit">
                <v-col class="text-center">
                  <div style="font-weight: 500">
                    <span style="padding: 10px; color: #5ca6d2">{{ itemEdit.manageId }}</span>
                    <span style="padding: 10px; color: #5ca6d2">{{ itemEdit.user.name }}</span>
                    <span style="padding: 10px;  color: #fb8c00">{{ itemEdit.furiganaName }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  md="1"
                  cols="2"
                  class="mt-5"
                >
                  <span class="font-weight-bold">
                    {{ $t('table.headers.name') }}
                  </span>
                </v-col>
                <v-col
                  md="4"
                  cols="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="inputSearch"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="itemEdit"
                      :items="listEmployees"
                      :loading="isLoading"
                      :search-input.sync="inputSearch"
                      :no-data-text="$t('table.messages.no_data')"
                      :item-text="getName"
                      item-value="id"
                      :error-messages="errors[0]"
                      name="inputSearch"
                      outlined
                      auto-select-first
                      return-object
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  md="1"
                  cols="2"
                  class="mt-5"
                >
                  <span class="font-weight-bold">
                    {{
                      $t('table.headers.customerName')
                    }}
                  </span>
                </v-col>
                <v-col
                  md="4"
                  cols="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="customer"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="customerIdPopup"
                      :items="customerList"
                      :error-messages="errors[0]"
                      :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                      item-value="id"
                      name="customer"
                      :no-data-text="$t('table.messages.no_data')"
                      clearable
                      single-line
                      outlined
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                style="border-radius:10px; border:1px solid #25ACD8"
              >
                <v-col cols="11">
                  <v-row
                    v-for="(item, index) in dateInWorks"
                    :key="index"
                    style="border-radius:10px; border:1px solid #25ACD8"
                    class="d-flex justify-space-beetween"
                  >
                    <v-col
                      md="5"
                      cols="12"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.workDay')
                          }}
                        </span>
                      </div>
                      <v-btn-toggle
                        v-model="item.workdate"
                        multiple
                      >
                        <v-btn
                          v-for="(i, index1) in itemsDay"
                          :key="index1"
                          class="mr-1"
                          color="#81bfff"
                        >
                          {{ i.text }}
                        </v-btn>
                      </v-btn-toggle>
                      <div
                        v-if="item.workdate.length < 1 && item.errorWorkdate"
                        class="red--text"
                      >
                        {{ $t('validator.required') }}
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      offset="1"
                      md="3"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.jobName')
                          }}
                        </span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="jobId"
                        rules="required"
                      >
                        <v-select
                          v-model="item.jobId"
                          item-text="name"
                          item-value="id"
                          :error-messages="errors[0]"
                          :items="jobListPopUp"
                          name="jobId"
                          solo
                          @change="changeJob(item.jobId, index)"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      md="3"
                      cols="6"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.shift')
                          }}
                        </span>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="shiftId"
                        rules="required"
                      >
                        <v-select
                          v-model="item.shiftId"
                          name="shiftId"
                          :error-messages="errors[0]"
                          :items="item.listShift"
                          :item-text="s => s.shiftStartAt + ' ~ ' + s.shiftEndAt"
                          item-value="id"
                          solo
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-btn @click="addFields">
                    +
                  </v-btn>
                  <v-btn
                    v-if="dateInWorks.length > 1"
                    class="mt-2"
                    @click="subtractFields"
                  >
                    -
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                large
                color="accent"
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
              <v-btn
                v-if="isEdit"
                class="text-h5"
                dark
                large
                color="#E43E08"
                @click="dialogDelete=true"
              >
                {{ $t('messages.delete') }}
              </v-btn>
              <v-btn
                large
                @click="closeDialog"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="updateShiftFixed(false)"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="dialogDelete=false"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { debounce } from 'lodash'
  import { required } from 'vee-validate/dist/rules'
  import { get } from 'vuex-pathify'
  export default {
    name: 'ParttimeList',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        isSelectingFile: true,
        fileIsValid: true,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            width: '4rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            width: '7rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            width: '11rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.furiganaName'),
            align: 'start',
            value: 'furiganaName',
            class: 'font-weight-bold',
            width: '11rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.customerName'),
            align: 'start',
            value: 'customerName',
            class: 'font-weight-bold',
            width: '10rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.job'),
            align: 'start',
            value: 'job',
            class: 'font-weight-bold',
            width: '6rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.shift'),
            align: 'start',
            value: 'shift',
            class: 'font-weight-bold',
            width: '14rem',
            sortable: false,
          },
          {
            text: this.$t('day.0'),
            align: 'start',
            value: '0',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.1'),
            align: 'start',
            value: '1',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.2'),
            align: 'start',
            value: '2',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.3'),
            align: 'start',
            value: '3',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.4'),
            align: 'start',
            value: '4',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.5'),
            align: 'start',
            value: '5',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
          {
            text: this.$t('day.6'),
            align: 'start',
            value: '6',
            class: 'font-weight-bold',
            width: '5rem',
            sortable: false,
          },
        ],
        editedIndex: -1,
        items: [],
        search: '',
        loading: false,
        totalItems: 1,
        pageNumber: 0,
        requestTypes: [],
        requestType: [],
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        customerSelected: null,
        jobLists: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        jobSelected: null,
        dialog: false,
        dateInWorks: [],
        dateInWork: {
          workdate: [],
          jobId: '',
          shiftId: '',
          errorShiftId: '',
          errorJobId: '',
          errorWorkdate: false,
          listShift: [],
        },
        itemsDay: [
          { value: 0, text: '日' },
          { value: 1, text: '月' },
          { value: 2, text: '火' },
          { value: 3, text: '水' },
          { value: 4, text: '木' },
          { value: 5, text: '金' },
          { value: 6, text: '土' },
        ],
        listShift: [],
        jobListPopUp: [],
        itemEdit: {
          name: '',
          manageId: '',
          user: {
            name: '',
          },
          furiganaName: '',
        },
        isEdit: false,
        dialogDelete: false,
        listEmployees: [],
        nameEmployee: null,
        isLoading: false,
        inputSearch: null,
        customerIdPopup: null,
      }
    },
    computed: {
      ...get('parttimer', ['list', 'totalCount']),
      ...get('shift', ['getAttendances', 'attendanceTotal', 'message', 'error', 'status']),
      customerList: get('customer@list.data.customers'),
      jobList: get('job@list'),
    },
    watch: {
      message (value) {
        if (value === 'updateSuccess') {
          this.showSnackBar('messages.updateSuccess')
        }
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      search () {
        this.onSearch()
      },
      error (value) {
        this.showSnackBar(value)
      },
      getAttendances (value) {
        this.$set(this, 'items', [...value])
      },
      status (value) {
        this.loading = value === 'loading'
      },
      attendanceTotal (value) {
        this.totalItems = value
      },
      customerList (value) {
        value.forEach(item => {
          this.customers.push(item)
        })
      },
      customerSelected (value) {
        this.jobSelected = null
        this.initialize()
        if (value) {
          this.getJobs(value)
        } else {
          this.jobLists = [
            {
              id: null,
              name: this.$t('messages.all'),
            },
          ]
        }
      },
      jobList (value) {
        this.jobLists = [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ]
        value.forEach(item => {
          this.jobLists.push(item)
        })
      },
      jobSelected (value) {
        this.initialize()
      },
      list (value) {
        this.listEmployees = [...value.parttimeEmployees]
      },
      inputSearch: debounce(async function (newVal) {
        if (this.isLoading) return
        if (!newVal) return
        if (newVal.includes(' - ')) return
        this.isLoading = true
        const params = {
          offset: 0,
          limit: 50,
          keyword: newVal,
          isFixed: false,
        }
        await this.$store.dispatch('parttimer/getParttimers', params)
        this.isLoading = false
      }, 500),
      async customerIdPopup (value) {
        if (value) {
          this.dateInWorks = [{ ...this.dateInWork }]
          const params = {
            isFixed: true,
            id: value,
            includeAll: true,
          }
          const customerList = await this.$store.dispatch('shift/getCustomer', params)
          this.jobListPopUp = [...customerList.jobs]
        } else {
          this.jobListPopUp = []
        }
      },
    },
    async created () {
      [0, 1, 2, 3, 4, 5, 6].map((i) => {
        this.requestTypes.push({
          name: this.$t(`day.${i}`),
          value: i,
        })
      })
      this.dateInWorks.push({ ...this.dateInWork })
      const params = {
        isFixed: true,
      }
      this.$store.dispatch('customer/getCustomers', params)
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
    },
    methods: {
      async initialize () {
        const tmpOptions = {
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage || 0,
        }

        if (this.search) {
          tmpOptions.keyword = this.search
        }
        if (this.customerSelected) {
          tmpOptions.customerId = this.customerSelected
        }
        if (this.jobSelected) {
          tmpOptions.jobId = this.jobSelected
        }
        if (this.requestType.length > 0) {
          tmpOptions.workdate = JSON.stringify(this.requestType)
        }

        await this.$store.dispatch('shift/getAttendanceList', tmpOptions)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      onSearch () {
        if (this.search) {
          this.options.page = 1
        }
        this.initialize()
      },
      async getJobs (value) {
        await this.$store.dispatch('job/getJobsCustomer', {
          id: value,
        })
      },
      changeRoute (a) {
        this.initialize()
      },
      changeJob (value, index) {
        const data = this.jobListPopUp.find((i) => i.id === value)
        this.dateInWorks[index].listShift = [...data.shifts]
        this.dateInWorks[index].shiftId = ''
      },
      addFields () {
        this.dateInWorks.push({ ...this.dateInWork })
      },
      subtractFields () {
        this.dateInWorks.pop()
      },
      closeDialog () {
        this.$refs.observer && this.$refs.observer.reset()
        this.dialog = false
      },
      async openPopupUpdate (data) {
        this.isEdit = true
        this.itemEdit = data.parttimeEmployee
        this.customerIdPopup = data.customer.id
        const params = {
          isFixed: true,
          id: data.customer.id,
          includeAll: true,
        }
        const customerList = await this.$store.dispatch('shift/getCustomer', params)
        this.dateInWorks = data.parttimeEmployee.fixedShifts.map((i) => {
          const k = customerList.jobs.find((s) => s.id === i.jobId)
          return {
            id: i.id,
            jobId: i.jobId,
            shiftId: i.shiftId,
            workdate: i.workdate,
            listShift: k.shifts,
          }
        })
        this.jobListPopUp = [...customerList.jobs]
        this.dialog = true
      },
      async updateShiftFixed (isUpdate = true) {
        var isSuccess
        const isvalidFixed = this.checkValidateParttimeFixed()
        const data = {
          isFixed: isUpdate,
          customerId: this.customerIdPopup,
          fixedShiftData: this.dateInWorks.map((i) => {
            return {
              jobId: i.jobId,
              shiftId: i.shiftId,
              workdate: i.workdate,
            }
          }),
        }
        if (isUpdate) {
          if (isvalidFixed) {
            data.isUpdate = !this.isEdit
            data.id = this.itemEdit.id
            isSuccess = await this.$store.dispatch('shift/updateFixedShift', data)
          }
        } else {
          data.id = this.itemEdit.id
          isSuccess = await this.$store.dispatch('shift/updateFixedShift', data)
        }
        if (isSuccess) {
          this.dialog = false
          this.dialogDelete = false
          this.initialize()
        }
      },
      checkValidateParttimeFixed () {
        var isValid = false
        this.dateInWorks.map((i) => {
          if (i.jobId && i.shiftId && i.workdate.length >= 1) {
            i.isValid = true
          } else {
            i.isValid = false
            i.errorWorkdate = true
          }
          i.errorWorkdate = i.workdate.length < 1
          return i
        })
        isValid = this.dateInWorks.every((i) => i.isValid === true)
        return isValid
      },
      async openPopupCreate () {
        this.$refs.observer && this.$refs.observer.reset()
        this.customerIdPopup = null
        this.jobListPopUp = []
        this.dialog = true
        this.isEdit = false
        this.itemEdit = {
          id: null,
          name: '',
          manageId: '',
          user: {
            name: '',
          },
          furiganaName: '',
        }
        this.dateInWorks = [{ ...this.dateInWork }]
      },
      deleteItemConfirm () {

      },
      getName (data) {
        return data.manageId + ' - ' + data.name
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
.colorActive {
  color: #1bc4bd;
}
.color-current-date {
  background-color:#B6EDFF;
  opacity : 78%;
}
</style>
